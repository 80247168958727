import PropTypes from 'prop-types';
import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import { AboutSectionContainer } from "@product-site-frontend/shared";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import PageBox from "../components/PageBox";
import SEO from '../components/SEO';

SingleBlog.propTypes = {
  data: PropTypes.object,
};

export default function SingleBlog({ data }) {
  let article = data.article;

  const styles = useMemo(() => ({
    title: {

    },
    wrapperImg: {
      maxWidth: '800px',
      margin: '0 auto 66px',
    },
    content: {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      marginBottom: '40px',
      fontSize: '22px',


      '& a': {
        color: '#2484c6',

        '&:hover': {
          textDecoration: 'underline',
        }
      }
    }
  }), []);

  return (
    <PageBox>
      <SEO pageData={{
        title: article.seoTitle ? article.seoTitle : article.title,
        description: article.seoDescription ? article.seoDescription : article.description,
      }} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            href: '/blog',
            text: 'Блог'
          },
          {
            text: article.title
          }
        ]}
      />
      <Box component="section" itemScope itemtype="http://schema.org/Article">
        <AboutSectionContainer component="div" sxContainer={{ mb: { xs: 0, lg: 0 } }}>
          <Typography itemProp="headline" sx={{ ...styles.title, mb: { xs: 0, md: 0, lg: 0 } }} variant="h1">
            {article.title}
          </Typography>
        </AboutSectionContainer>

        <AboutSectionContainer component="div" sxContainer={{ mb: { xs: 0, lg: 0 } }}>
          <Box itemProp="image" itemScope itemtype="http://schema.org/ImageObject" sx={styles.wrapperImg}>
            <img alt={article.title} itemProp="url contentUrl" src={article.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
          </Box>

          <Box component="article" itemProp="articleBody" sx={styles.content}>
            <ReactMarkdown
              transformImageUri={uri => uri.startsWith('http') ? `https://images.arenza.ru/blog${uri.replace("/uploads/", '/')}` : `http://images.arenza.ru/blog${uri.replace("/uploads/", '/')}`}
            >
              {article.content}
            </ReactMarkdown>
          </Box>
        </AboutSectionContainer>
        <meta content={`${article.published_at}`} itemProp="datePublished" />
        <meta
          itemID={`${process.env.GATSBY_ROOT_URL}/blog/${article.slug}`}
          itemProp="mainEntityOfPage"
          itemScope
          itemType="https://schema.org/WebPage"
        />
      </Box>
    </PageBox>
  )
}

export const query = graphql`
  query BlogQuery($strapiId: Int) {
    article: strapiArticles(strapiId: {eq: $strapiId}) {
      content
      title
      seoTitle
      description
      seoDescription
      slug
      published_at
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;